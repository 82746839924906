.sectionWrapper {
  @apply w-full py-4 lg:py-16;
}

.sectionInner {
  @apply w-full max-w-[1200px] container relative;
  @apply flex flex-wrap justify-center;
  @apply lg:justify-between items-center gap-x-4 gap-y-4 lg:gap-y-16;
}

.logo {
  @apply max-w-[60px];
}
