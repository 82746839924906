.wrapper {
  @apply flex flex-grow flex-shrink w-full;
  @apply cursor-pointer text-purple hover:text-purple-button-hover;

  &.vertical {
    @apply block;
  }

  &.horizontal {
    @apply flex flex-row items-start gap-x-16;
  }

  &.reversed {
    @apply flex-row-reverse;
  }

  .resort {
    @apply mb-4 text-15-23 italic h-8;

    &.big {
      @apply mb-3 h-auto;
    }
  }

  .content {
    @apply pr-4;

    &.horizontal {
      @apply w-1/2;
    }

    .title {
      @apply text-20-30 font-semibold mb-6;
    }

    .intro {
      @apply text-16-25 mb-6;
    }

    .infoWrapper {
      @apply flex;

      .info {
        @apply flex items-center text-12-18;

        &:first-of-type {
          @apply mr-8;
        }
      }
    }

    &.big {
      .title {
        @apply text-26-39 mb-4;
      }
    }

    .icon {
      @apply mr-1 w-3 h-3;
    }
  }
  .imageWrapper {
    @apply relative;

    &.vertical {
      @apply relative w-full h-[200px] mb-8;
    }

    &.horizontal {
      @apply w-1/2 relative self-stretch h-[200px];
    }

    &.big {
      @apply h-[300px];
    }
  }
}
