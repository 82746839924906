.sectionWrapper {
  @apply w-full overflow-x-hidden pt-8 lg:pt-24 pb-4 md:pb-12;

  &.noSection {
    @apply pt-8 pb-0;
  }
}

.sectionInner {
  @apply relative w-full;
  &.noSection {
    @apply block;
  }

  &.section {
    @apply container md:flex md:flex-wrap;
  }
}

.heroLeft {
  &.noSection {
    @apply text-left;
  }

  &.section {
    @apply mb-4 md:mb-0;
  }
}

.headline {
  @apply font-semibold text-36-40 lg:text-60-70 break-words mb-8;

  &.noSection {
  }

  &.section {
    @apply flex justify-center items-center flex-wrap;

    &.noMedia {
      @apply block;
    }

    span {
      @apply w-full;
    }
  }
}

.teaser {
  @apply text-15-23 md:text-24-35 mb-4;
}

.withOverlap {
  @apply h-full relative md:absolute md:left-1/2 md:top-0;
  @apply block md:flex;

  img,
  div {
    @apply h-full w-full md:w-auto md:self-stretch;
    @apply max-w-full md:max-w-none;
  }
}

.withoutOverlap {
  @apply w-full md:w-1/2 block md:pl-8;
}
