.wrapper {
  @apply mt-20;
  //@apply grid auto-rows-fr gap-x-16 gap-y-12;
  //@apply grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5;

  @apply flex items-center flex-wrap gap-x-16 gap-y-12;

  &.sectionWrapper {
    @apply container my-16;
  }

  &.divWrapper {
    @apply px-10 md:px-40;
  }

  .logo {
    @apply relative max-h-[55px];

    &:first-of-type {
      @apply xl:ml-16;
    }
  }

  .teaser {
    @apply text-16-25 max-w-[250px];
  }
}
