.sectionWrapper {
  @apply w-full overflow-hidden pt-8 lg:pt-24 pb-4 md:pb-12;

  &.noSection {
    @apply pt-8 pb-0;
  }
}

.sectionInner {
  @apply container relative w-full;
  @apply block lg:flex lg:flex-nowrap;
  @apply mb-4 md:mb-0;
}

.heroLeft {
  @apply w-full lg:w-1/2;
}

.heroRight {
  @apply w-full lg:w-1/2 relative;

  .videoBackground,
  .videoForeground {
    @apply rounded-[20px] lg:rounded-[30px];
    @apply overflow-hidden;
  }

  .videoBackground {
    @apply w-[120%] sm:w-full lg:w-[900px];

    @apply mt-16 lg:mt-0;
    @apply relative;
    @apply left-8 bottom-2;
    @apply sm:bottom-4 sm:left-24;
    @apply md:left-32;
    @apply lg:absolute lg:-bottom-20 lg:left-48;
  }

  .videoForeground {
    @apply h-auto w-[40%] sm:w-[35%] md:w-[31%] lg:w-[265px];
    @apply absolute;
    @apply -bottom-2 -left-4;
    @apply sm:-bottom-2 sm:-left-4;
    @apply md:bottom-0 md:left-2;
    @apply lg:-bottom-8 lg:left-12;
  }
}

.headline {
  @apply font-semibold text-36-40 lg:text-60-70 break-words mb-8;

  &.noSection {
  }

  &.section {
    @apply flex justify-center items-center flex-wrap;

    &.noMedia {
      @apply block;
    }

    span {
      @apply w-full;
    }
  }
}

.teaser {
  @apply text-15-23 md:text-24-35 mb-4;
}

.withOverlap {
  @apply h-full relative md:absolute md:left-1/2 md:top-0;
  @apply block md:flex;

  img,
  div {
    @apply h-full w-full md:w-auto md:self-stretch;
    @apply max-w-full md:max-w-none;
  }
}

.withoutOverlap {
  @apply w-full md:w-1/2 block md:pl-8;
}
