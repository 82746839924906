.sectionWrapper {
  @apply container mt-4 mb-12 lg:my-32 md:pb-[4rem];
}

.sectionInner {
  @apply grid gap-4;
  @apply grid-cols-4 grid-rows-[1fr_1fr_1fr_auto];
  @apply md:grid-cols-2 md:grid-rows-2;
  @apply lg:grid-cols-12 lg:grid-rows-2;
}

.firstImage {
  @apply relative;
  @apply col-start-1 col-span-4 row-start-1 row-span-1 min-h-[330px];
  @apply md:col-start-1 md:col-span-1 md:row-start-1 md:row-span-1;
  @apply lg:col-start-1 lg:row-start-1 lg:col-span-8 lg:row-span-1;
}

.secondImage {
  @apply relative;
  @apply col-start-1 col-span-4 row-start-2 row-span-1;
  @apply md:col-start-2 md:col-span-1 md:row-start-1 md:row-span-1;
  @apply lg:col-start-9 lg:row-start-1 lg:col-span-3 lg:row-span-1;
}

.thirdImage {
  @apply relative;
  @apply col-start-1 col-span-4 row-start-3 row-span-1;
  @apply md:col-start-1 md:col-span-1 md:row-start-2 md:row-span-1;
  @apply lg:col-start-2 lg:row-start-2 lg:col-span-5 lg:row-span-1;
}

.teaser {
  @apply col-start-1 col-span-4 row-start-4 row-span-1;
  @apply md:col-start-2 md:col-span-1 md:row-start-2 md:row-span-1;
  @apply lg:col-start-7 lg:row-start-2 lg:col-span-6 lg:row-span-1;
  @apply md:pl-[4.25rem] md:transform md:translate-y-[4rem];
  @apply text-24-35 lg:text-40-55 break-words;
}
