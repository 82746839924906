.card {
  @apply flex rounded-2xl;
  @apply transition-colors duration-300 ease-in-out;
  @apply bg-yellow text-purple;
  @apply hover:bg-purple hover:text-white;
}

.cardInner {
  @apply flex flex-col justify-between;
  @apply p-8 text-center;
}

.headline {
  @apply text-24-35;
}
