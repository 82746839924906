.wrapper {
  @apply flex lg:hidden w-24 h-24;
  @apply items-center justify-center;
  @apply rounded-full;
  @apply bg-yellow text-purple;
  z-index: 100;

  @apply fixed bottom-4 md:bottom-8 right-4 md:right-8;

  svg {
    @apply w-16 h-16;
  }
}
