.slide {
  //FIXED HEIGHT BECAUSE SWIPER AUTO HEIGHT DOESN'T WORK
  @apply w-full h-[350px] rounded-2xl;
  @apply px-[2rem] py-[1.5rem] md:px-[2rem] md:py-[2rem];

  .icon {
    @apply mb-6;
  }

  .headline {
    @apply text-18-31 md:text-24-31 font-medium;
    @apply mb-4;
  }

  .teaser {
    @apply text-15-23 md:text-18-27;
  }
}
