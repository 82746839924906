.twoColumnJump {
  @apply flex flex-wrap;
  @apply container mx-auto my-16;

  .twoColumnJumpIndex {
    @apply pt-4 pb-8 px-4 mb-8 lg:px-8 lg:p-4;
    @apply border-b border-gray-terminal-border lg:border-none;

    @apply w-full lg:w-1/2;
    @apply flex-grow-0 flex-shrink-0;
    @apply relative lg:sticky top-0 pt-28 self-start;

    .teaser {
      @apply md:text-24-35 text-15-23;
    }

    .link {
      @apply mt-8 mb-4;
    }

    .noDisplay {
      @apply invisible h-0 w-0 m-0 p-0;
    }

    .anchorLink {
      @apply block w-full mb-2;
      @apply md:text-40-55 text-24-35;

      &.active {
        @apply font-semibold;
        @apply flex items-baseline;

        &:before {
          content: '';
          @apply block w-[26px] -ml-[36px] mr-[10px];
          @apply border-y-[13px] border-y-transparent;
          @apply border-l-[13px] border-l-purple;
        }
      }
    }
  }

  .twoColumnJumpContent {
    @apply w-1/2;
    @apply flex-grow flex-shrink;
    @apply pl-0 lg:pl-16;

    .anchorTarget {
      @apply block relative -top-20 lg:top-0;

      &:first-of-type {
        @apply -top-40 pt-20;
        @apply lg:top-0 lg:pt-28;
      }
    }
  }
}
