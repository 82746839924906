.sectionWrapper {
  @apply container my-16;
}

.factsWrapper {
  @apply flex flex-wrap justify-around gap-16;

  .factWrapper {
    @apply flex flex-col flex-grow-0 flex-shrink-0;
    @apply basis-full sm:basis-1/5;

    .factIconWrapper {
      @apply relative w-8 h-8 mb-4;
    }

    .factTextWrapper {
      @apply break-words;
    }
  }
}
