.sectionWrapper {
  @apply container flex flex-wrap gap-8 my-16;
}

.fullCard {
  @apply w-full;
  @apply md:flex flex-row-reverse flex-wrap;
  @apply bg-purple-dark rounded-2xl overflow-hidden lg:min-h-[580px];

  .cardTextWrapper {
    @apply flex flex-col flex-grow justify-center basis-full sm:basis-1/2;
    @apply text-white p-8 lg:px-24;
  }

  .cardImageWrapper {
    @apply relative flex-grow-0 flex-shrink-0 sm:basis-1/2 basis-full;
    @apply h-[180px] md:h-full;

    img {
      @apply w-full h-auto;
    }
  }
}

.halfCard {
  @apply overflow-hidden rounded-2xl bg-purple-dark;
  @apply w-full;

  .cardTextWrapper {
    @apply flex flex-col justify-center;
    @apply text-white p-4;
  }

  .cardImageWrapper {
    @apply relative;
    @apply h-[180px];

    img {
      @apply w-full h-auto;
    }
  }

  @screen md {
    @apply flex flex-row-reverse flex-wrap;

    .cardTextWrapper {
      @apply flex flex-col flex-grow justify-center basis-1/2;
      @apply text-white p-8;
    }

    .cardImageWrapper {
      @apply relative flex-grow-0 flex-shrink-0 basis-1/2;
      @apply h-[180px];

      img {
        @apply w-full h-auto;
      }
    }
  }

  @screen lg {
    aspect-ratio: unset;
    @apply w-[calc(50%-1rem)];
    @apply flex flex-row flex-wrap;

    .cardTextWrapper {
      @apply flex flex-col justify-center;
      @apply flex-grow-0 flex-shrink-0 basis-full;
      @apply text-white p-16;
    }

    .cardImageWrapper {
      @apply relative;
      @apply flex flex-grow flex-shrink-0 basis-full;
      @apply h-[180px];

      img {
        @apply w-full h-auto;
      }
    }
  }
}
