.sectionWrapper {
  @apply container overflow-hidden my-12 w-full;
}

.swiper {
  @apply col-start-2 md:col-start-3;
  @apply col-span-10 md:col-span-8;
  @apply h-full;

  :global(.swiper-button-prev),
  :global(.swiper-button-next) {
    @apply h-[72px] w-[72px] bg-purple text-white;
    @apply rounded-full;
    @apply hidden md:flex justify-center items-center;
  }

  //:global(.swiper-slide-prev) {
  //  -webkit-mask-image: -webkit-gradient(
  //    linear,
  //    65% 100%,
  //    100% 100%,
  //    from(rgba(0, 0, 0, 0)),
  //    to(rgba(0, 0, 0, 1))
  //  );
  //}
  //
  //:global(.swiper-slide-next) {
  //  mask-image: -webkit-gradient(
  //    linear,
  //    35% 100%,
  //    0% 100%,
  //    from(rgba(0, 0, 0, 0)),
  //    to(rgba(0, 0, 0, 1))
  //  );
  //}
}

.swiperPagination {
  @apply w-full min-h-[14px] mt-8;
  @apply flex relative justify-center items-center;
}

.swiperPaginationBullet {
  @apply block w-[14px] h-[14px] mr-[10px];
  @apply rounded-full opacity-100 bg-gray-lighter;

  &:global(.swiper-pagination-bullet-active) {
    @apply bg-purple;
  }
}
