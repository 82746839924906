.sectionWrapper {
  @apply container my-16;
}

.benefitsWrapper {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 auto-rows-fr;
}

.benefitWrapper {
  @apply flex flex-col items-center justify-center p-4 rounded-2xl;
}

.benefitIconWrapper {
  @apply relative w-8 h-8 mb-4;
}

.benefitTextWrapper {
  @apply break-words text-center px-8;
}

.deco {
  @apply absolute bg-yellow inset-0 translate-x-1/3;
}
