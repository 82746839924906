.sectionWrapper {
  @apply container my-8 md:my-16 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 break-words;
}

.methodWrapper {
  @apply rounded-2xl px-8 md:px-14 py-8 bg-white;
  @apply shadow-[0_0_30px_rgba(0,0,0,0.16)];
}

.methodImageWrapper {
  @apply flex items-center justify-center rounded-2xl h-[158px] mb-6 md:mb-14;
}

.listIcon {
  @apply mt-1 mr-3 w-4 h-4 stroke-[3px];
}

.lastItemWrapper {
  @apply flex flex-col justify-center items-center;
  @apply rounded-2xl bg-purple min-h-[456px] px-11 pt-[5.75rem] pb-16;
  @apply shadow-[0_0_30px_rgba(0,0,0,0.16)];
}

.plusIconWrapper {
  @apply rounded-full p-6;
  @apply h-[104px] w-[104px];
  @apply flex items-center justify-center;
  @apply stroke-[5px] mb-14;
  @apply bg-yellow hover:bg-yellow-light;
}
