.containerWrapper {
  @apply container w-full h-auto relative;
  @apply my-16 overflow-hidden;
  @apply text-white;
  @apply min-h-[280px] lg:min-h-[380px] lg:min-h-[580px];
}

.containerInner {
  @apply w-full h-full;
}

.slide {
  @apply w-full h-full rounded-2xl overflow-hidden;
  @apply relative object-cover object-center;
  @apply flex items-center;
  @apply py-4 md:py-16 lg:py-32;
  @apply relative;
  @apply min-h-[280px] lg:min-h-[380px] lg:min-h-[580px];

  .backgroundImage {
    @apply rounded-2xl -z-1;
  }

  .overlay {
    @apply w-full h-full absolute opacity-50;
    @apply bg-gradient-to-r from-black;
    @apply z-10;
  }

  .textContent {
    @apply z-20 w-4/5 md:w-2/3 lg:w-1/2;
    @apply p-4 md:pl-16 lg:pl-24;

    .headline {
      @apply md:text-40-55 text-24-35 font-medium;
      @apply mb-6;
    }

    .teaser {
      @apply md:text-24-35 text-15-23;
    }

    &.small {
      @apply absolute p-0 left-32 bottom-22;
      @apply left-4 bottom-4 lg:left-32 lg:bottom-22;
      @apply max-w-[450px];

      .headline {
        @apply text-24-35 font-medium;
        @apply mb-1;
      }

      .teaser {
        @apply text-24-35;
      }
    }
  }
}

.swiper {
  @apply w-full min-h-[10vh];

  :global(.swiper-button-prev),
  :global(.swiper-button-next) {
    @apply h-[72px] w-[72px] bg-white text-purple;
    @apply rounded-full;
    @apply hidden lg:flex justify-center items-center;
  }

  :global(.swiper-button-prev) {
    @apply ml-4;
  }

  :global(.swiper-button-next) {
    @apply mr-4;
  }
}

.swiperPagination {
  @apply w-full min-h-[14px] mt-8;
  @apply flex relative justify-center items-center;
}

.swiperPaginationBullet {
  @apply block w-[14px] h-[14px] mr-[10px];
  @apply rounded-full opacity-100 bg-gray-lighter;

  &:global(.swiper-pagination-bullet-active) {
    @apply bg-yellow;
  }
}
