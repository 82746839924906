.twoImages {
  @apply w-full py-16 overflow-x-hidden;

  .twoImagesInner {
    @apply container sm:flex;

    .imageLeftOverflowLeft {
      @apply w-full sm:w-8/12 -translate-x-4 sm:-translate-x-[8.333333%] mb-2 sm:mb-0;

      div {
        @apply relative w-full aspect-[1.8/1];
      }
    }

    .imageRightOverflowLeft {
      @apply w-2/3 sm:w-1/3 translate-x-4 sm:translate-x-0;

      div {
        @apply relative w-full aspect-[0.75/1];
      }
    }

    .imageLeftOverflowRight {
      @apply w-full sm:w-2/3 -translate-x-1/4 sm:-translate-x-0;

      div {
        @apply relative w-full mb-2 sm:mb-0 aspect-[1.5/1];
      }
    }

    .imageRightOverflowRight {
      @apply w-full sm:w-1/2 translate-x-1/4;

      div {
        @apply relative w-full aspect-[1.5/1];
      }
    }

    .imageLeftNoOverflow {
      @apply w-full sm:w-7/12;

      div {
        @apply relative w-full mb-2 sm:mb-0 aspect-[1.7/1];
      }
    }

    .imageRightNoOverflow {
      @apply w-3/4 sm:w-1/3 sm:mt-[60px] translate-x-1/3 sm:translate-x-1/4;

      div {
        @apply relative w-full aspect-[0.85/1];
      }
    }
  }
}
