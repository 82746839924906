.newsletter {
  @apply relative bg-purple-lightest rounded-[3rem];

  .patternWrapper {
    @apply absolute top-0 left-0 w-full h-full overflow-hidden rounded-[3rem];

    .pattern1 {
      @apply absolute -bottom-80 -left-22;
    }

    .pattern2 {
      @apply absolute -top-20 -right-20;
    }
  }

  .contentWrapper {
    @apply pb-16 md:pb-8 pt-48 md:pt-8 px-4 md:px-32 relative;

    .hand {
      @apply absolute -top-16 -right-2 md:top-1/2 md:-translate-y-1/2 md:right-10;
    }

    .loadingSpinner {
      @apply mx-auto h-32 w-32 animate-spin stroke-white;
      animation: spin 2s linear infinite;

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }

    .checkmark {
      @apply mx-auto h-32 w-32 rounded-full border-8 p-1 stroke-white border-white;
      animation: ping 1s cubic-bezier(0, 0, 0.2, 1) forwards;

      @keyframes ping {
        0% {
          transform: scale(0);
          opacity: 30%;
        }
        75%,
        100% {
          transform: scale(1);
          opacity: 100%;
        }
      }
    }

    .x {
      @apply mx-auto h-20 w-20 rounded-full border-8 p-1 stroke-white border-white;
      animation: ping 1s cubic-bezier(0, 0, 0.2, 1) forwards;

      @keyframes ping {
        0% {
          transform: scale(0);
          opacity: 30%;
        }
        75%,
        100% {
          transform: scale(1);
          opacity: 100%;
        }
      }
    }

    .retryWrapper {
      @apply flex items-center mt-4;

      .retryButton {
        @apply mx-auto;
      }
    }

    .headline,
    .teaser {
      @apply text-center;
    }

    .headline {
      @apply text-white mb-0 md:mb-6;

      :last-child {
        @apply mb-0;
      }
    }

    .teaser {
      @apply text-black mb-9;
    }

    .form {
      @apply flex flex-col items-center;

      input {
        box-shadow: 9px 8px 0px 0px #212840;
        @apply w-full pl-8 pr-8 md:pr-24 py-4 rounded-2xl border-none mb-6;
      }
    }
  }
}
