.sectionWrapper {
  @apply container my-4 lg:my-16 flex flex-col items-center;
}

.headline {
  @apply text-40-55 font-semibold mb-10;
}

.cardsWrapper {
  @apply grid mb-10 grid-cols-1 gap-7 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 auto-rows-fr;
}
