.twoColumnText {
  @apply w-full py-16;
  .twoColumnTextInner {
    @apply container md:flex;

    .textLeft,
    .textRight {
      @apply md:w-1/2 flex-shrink-0 text-15-23 md:text-24-30;

      p:first-child {
        @apply mt-2;
      }

      :last-child {
        @apply mb-0;
      }
    }

    .textLeft {
      @apply mb-4 md:mb-0;
    }
  }
}
