.containerWrapper {
  @apply sm:container w-full my-16 xl:rounded-2xl overflow-hidden;
}

.containerInner {
  @apply sm:rounded-2xl py-8 md:py-28;

  &.noContent {
    @apply py-16;
  }
}

.headlineWrapper {
  @apply px-10 md:px-40 mb-10;

  &.alignCenter {
    @apply text-center;
  }
  &.alignLeft {
    @apply text-left;
  }
  &.alignRight {
    @apply text-right;
  }

  &.noContent {
    @apply px-8 mb-4;
  }
}

.headline {
  @apply md:text-40-55 text-24-35 font-medium;
}

.teaser {
  @apply md:text-24-35 text-15-23;
}
