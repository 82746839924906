.sectionWrapper {
  @apply container lg:px-0;
}

.sectionSlider {
  @apply grid grid-cols-12 grid-rows-1 gap-4;
}

.swiper {
  @apply col-start-2 md:col-start-3;
  @apply col-span-10 md:col-span-8;

  :global(.swiper-button-prev),
  :global(.swiper-button-next) {
    @apply h-[72px] w-[72px] bg-white text-purple;
    @apply rounded-full;
    @apply hidden lg:flex justify-center items-center;
  }

  :global(.swiper-button-prev) {
    @apply ml-4;
  }

  :global(.swiper-button-next) {
    @apply mr-4;
  }
}

.swiperPagination {
  @apply w-full min-h-[14px] mt-8;
  @apply flex relative justify-center items-center;
}

.swiperPaginationBullet {
  @apply block w-[14px] h-[14px] mr-[10px];
  @apply rounded-full opacity-100 bg-gray-lighter;

  &:global(.swiper-pagination-bullet-active) {
    @apply bg-yellow;
  }
}
