.inputText {
  @apply w-full mb-4 h-16;
  @apply border rounded-[0.3125rem];
  @apply placeholder-purple;
  @apply focus:border-purple focus:ring-0;
}

.smallLabel {
  @apply text-12-18;
  @apply absolute top-2 left-8;
  @apply ease-in-out duration-200;
}

.inputTextarea {
  @apply w-full mb-4 h-56;
  @apply border rounded-[0.3125rem];
  @apply placeholder-purple;
  @apply focus:border-purple focus:ring-0;
}

.inputError {
  @apply -mt-2 mb-4 pl-1 text-red-600 text-14-24 italic;
}

.inputSelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.inputSelectOption {
  @apply bg-white text-purple;
}

.privacyLink {
  @apply text-yellow hover:text-yellow-light underline;
}
