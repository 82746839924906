.instagramFeed {
  @apply w-full py-16;

  .texts {
    @apply container mb-16;

    .headline {
      @apply mb-6;

      :last-child {
        @apply mb-0;
      }
    }

    .teaser {
      @apply text-24-30 md:w-2/3;
      :last-child {
        @apply mb-0;
      }
    }
  }

  .posts {
    @apply 3xl:max-w-[1600px] mx-auto grid grid-cols-2 md:grid-cols-4 grid-rows-2;

    .post {
      @apply aspect-square bg-no-repeat bg-cover bg-center relative;

      .icon {
        @apply absolute top-2 right-2 w-6 h-6 z-10;

        .reel {
          @apply rotate-180 fill-white;
        }

        .video {
          @apply fill-white;
        }
      }
    }
  }
}
