.containerWrapper {
  @apply container w-full h-auto relative;
  @apply my-16 overflow-hidden;
}

.containerInner {
  @apply flex flex-wrap;

  .info {
    @apply w-full lg:w-1/2 lg:px-8;

    .headline {
      @apply font-semibold text-36-40 md:text-60-70;
    }

    .featureList {
      @apply mt-16;

      .featureItem {
        &:before {
          content: '';
        }

        @apply flex items-center mb-4;

        svg {
          @apply mr-4 h-full stroke-[3px];
        }
      }
    }

    .address {
      @apply mt-16 mb-8;

      p {
        @apply mt-2;
      }
    }
  }

  .form {
    @apply w-full lg:w-1/2 lg:px-8 self-end;

    &.messageSent {
      @apply self-start;
    }

    a[target='_blank'] {
      text-decoration: underline;
    }
  }
}
