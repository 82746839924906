.sectionWrapper {
  @apply container my-16;

  .headline {
    @apply w-full lg:pl-[25%];
    @apply font-semibold text-36-40 lg:text-60-70 break-words mb-8;
  }

  .sectionInner {
    @apply w-full flex flex-wrap relative;

    .indexWrapper {
      @apply pt-4 pb-8 px-4 mb-8 lg:px-8 lg:p-4;
      @apply border-b border-purple lg:border-none;

      @apply break-words text-18-27;
      @apply w-full lg:w-1/4;
      @apply flex-grow-0 flex-shrink-0;
      @apply relative lg:sticky top-0 lg:top-24 self-start;

      .anchorLink {
        @apply block w-full mt-2;

        &.noDisplay {
          @apply mt-0;
        }

        &.active {
          @apply font-semibold;
          @apply flex items-baseline;

          &:before {
            content: '';
            @apply block w-[8px] -ml-[18px] mr-[10px];
            @apply border-y-[4px] border-y-transparent;
            @apply border-l-[4px] border-l-purple;
          }
        }
      }
    }

    .contentWrapper {
      @apply w-full lg:w-3/4;
      @apply flex-grow flex-shrink;
      @apply p-4 lg:p-0;

      @apply break-words;

      .anchorTarget {
        @apply block relative invisible -top-40;
      }

      :global(p) {
        @apply mb-4 mt-2;
      }

      :global(h3) {
        @apply text-24-35 font-semibold mb-4 mt-12;
      }
    }
  }
}

.noDisplay {
  margin: 0;
  @apply md:invisible md:h-0 md:w-0 md:m-0 md:p-0 mt-2;
}
