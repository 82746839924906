.sectionWrapper {
  @apply my-16;
}

.inColoredContainer {
  @apply px-10 md:px-40 mb-12;
}

.wrapperInner {
  @apply block md:flex;
}

.separator {
  @apply border-gray-lightest border-dashed;
  @apply w-full border-b my-6;
  @apply md:w-px md:border-r md:mx-6 md:my-0 md:border-b-0;
}
