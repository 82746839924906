.tooltipContent {
  @apply bg-yellow text-purple;

  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
}

.tooltipArrow {
  @apply text-yellow fill-current;
}
