.codeSnippet {
  @apply bg-[#212840] mb-8 p-8 rounded-md relative;

  .copyButton {
    @apply absolute right-4 top-4;
    @apply text-purple-light text-12-18;
    @apply cursor-pointer hover:bg-purple-light hover:text-white;
    @apply rounded-md py-1 px-2;
  }

  pre {
    @apply overflow-x-scroll;
    &::-webkit-scrollbar-track {
      @apply bg-[#212840];
    }

    &::-webkit-scrollbar {
      @apply h-1 bg-[#7B8ECB];
    }

    &::-webkit-scrollbar-thumb {
      @apply rounded-sm;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #db9f07;
    }
  }

  :global(code) {
    :global(.token-line) {
      @apply text-[#7B8ECB];
      :global(.punctuation) {
        @apply text-white;
      }
      :global(.property) {
        @apply text-yellow;
      }

      :global(.property + .operator) {
        @apply text-white;
      }
    }
  }
}
