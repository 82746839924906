.containerWrapper {
  @apply overflow-hidden;
}

.containerInner {
  @apply relative w-full container;

  &[data-width='25'] {
    @apply md:w-1/4;
  }
  &[data-width='50'] {
    @apply md:w-3/4 lg:w-1/2;
  }
  &[data-width='75'] {
    @apply md:w-3/4;
  }
  &[data-width='100'] {
    @apply md:w-full;
  }
}
