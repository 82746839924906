.sectionWrapper {
  @apply container my-16;

  .headline {
    @apply w-full lg:pl-[25%];
    @apply font-semibold text-36-40 lg:text-60-70 break-words mb-8;
  }

  .sectionInner {
    @apply w-full flex flex-wrap relative;

    .indexWrapper {
      @apply pt-4 pb-8 px-4 mb-8 lg:px-8 lg:p-4;
      @apply border-b border-purple lg:border-none;

      @apply break-words text-18-27;
      @apply w-full lg:w-1/3;
      @apply flex-grow-0 flex-shrink-0;
      @apply relative lg:sticky top-0 lg:top-24 self-start;

      .disclosureButton {
        @apply flex items-center justify-between w-full;
        @apply font-bold pt-8;
      }

      .navItems {
        @apply list-none px-0 my-0 mt-4;

        a {
          @apply cursor-pointer;
        }

        li {
          @apply mb-2;

          &.active {
            @apply font-medium;
          }

          &.level-0 {
            @apply pl-0;
          }

          &.level-1 {
            @apply pl-2;
          }

          &.level-2 {
            @apply pl-4;
          }
        }
      }

      @screen lg {
        height: calc(100vh - 6rem);
        @apply overflow-auto -mb-[65px];
      }
    }

    @screen lg {
      .indexWrapper::after {
        content: '.';
        @apply text-transparent h-[100px] inline-block;
      }
    }

    .contentWrapper {
      @apply w-full lg:w-2/3;
      @apply flex-grow flex-shrink;
      @apply p-4 lg:p-0;

      @apply break-words;

      .anchorTarget {
        @apply block relative invisible -top-40;
      }

      .mediablockset {
        @apply mb-8 my-6;
      }

      table {
        @apply w-full max-w-fit block whitespace-nowrap overflow-x-auto;

        tr:first-of-type {
          th {
            @apply pb-4 border-b border-[#2E3859] text-18-27 font-medium;
          }
        }

        tr:not(:first-of-type) {
          td {
            @apply py-10 border-b border-[#E6E8F2];
          }
        }

        tr:last-of-type {
          td {
            @apply border-none;
          }
        }

        th,
        td {
          @apply text-left max-w-[200px] break-words whitespace-pre-wrap pr-6;
          vertical-align: top;

          p {
            &[data-bool-icon] {
              color: transparent !important;
              @apply block flex items-center justify-center;
              @apply w-9 h-9 rounded-full mx-auto;

              svg {
                @apply w-6 h-6 block stroke-2;
              }
            }

            &[data-bool-icon='true'] {
              @apply bg-green-bool-icon-bg;

              svg {
                @apply stroke-green-bool-icon-text;
              }
            }

            &[data-bool-icon='false'] {
              @apply bg-red-bool-icon-bg;

              svg {
                @apply stroke-red-bool-icon-text;
              }
            }
          }
        }
      }

      @screen lg {
        @apply pl-6;
      }
    }
  }
}

.noDisplay {
  margin: 0;
  @apply md:invisible md:h-0 md:w-0 md:m-0 md:p-0 mt-2;
}
