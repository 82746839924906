.sectionWrapper {
  .sectionInner {
    @apply container flex flex-col items-center;

    .quote {
      @apply sm:w-2/3 text-36-40 lg:text-60-70 mb-14 md:mb-28;
    }

    .name,
    .job {
      @apply w-full sm:w-2/3 flex flex-col items-start text-15-23 sm:text-18-27;
    }
  }
}
