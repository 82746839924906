.sectionWrapper {
  @apply w-full py-16;

  .sectionWrapperInner {
    @apply container flex flex-wrap justify-between gap-y-16;
  }

  .sectionWrapperInnerSmall {
    @apply container w-full md:w-10/12;

    .headline {
      @apply mb-4 sm:mb-12;
    }

    .employeesWrapper {
      @apply grid grid-cols-1 xs:grid-cols-3 gap-7 sm:gap-14;

      .employee {
        .employeeImage {
          @apply relative w-full aspect-[0.75/1] mb-1;
        }
      }
    }
  }
}

.wrapperLarge {
  @apply flex justify-between w-full;

  .left {
    @apply w-1/2 md:w-4/12 lg:w-7/12 h-full lg:min-h-[25rem] min-h-[15.5rem];

    .portraitWrapper {
      @apply relative w-full h-full;
    }
  }

  .right {
    @apply pl-4 md:pl-2 flex flex-col justify-center w-7/12 lg:w-4/12 h-full;
  }
}

.wrapperMedium {
  @apply flex justify-between justify-between w-full lg:w-8/12 lg:pr-[1.625rem];

  .left {
    @apply w-1/2 md:w-4/12 lg:w-4/8 h-full;

    .portraitWrapper {
      @apply relative w-full h-full lg:h-3/4 mb-2 min-h-[15.5rem];
    }
  }

  .right {
    @apply pr-4 md:pr-2 w-7/12 lg:w-4/8 h-full flex flex-col justify-center;
  }
}

.wrapperSmall {
  @apply flex lg:flex-col justify-between w-full lg:w-4/12;

  .left {
    @apply w-1/2 md:w-4/12 lg:w-full h-full;

    .portraitWrapper {
      @apply relative w-full h-full lg:h-3/4 mb-2 min-h-[15.5rem];
    }
  }

  .right {
    @apply pl-4 md:pl-2 w-7/12 lg:hidden flex flex-col justify-center;
  }
}
