.slide {
  @apply w-full h-full bg-gray-lighter rounded-2xl;
  @apply px-[1.5rem] py-[2rem] md:px-[6rem] md:py-[4.5rem];
  @apply flex flex-col justify-between;

  .rating {
    @apply flex items-center;
    @apply mb-8;

    .star {
      @apply w-4 h-4 mr-[5px];

      @apply stroke-yellow;
      &.fill {
        @apply stroke-yellow fill-yellow;
      }
    }
  }

  .quote {
    @apply mb-0 md:mb-20;
    @apply text-15-23 md:text-24-35 font-medium;
  }

  .employee {
    @apply md:flex items-center;

    .icon {
      @apply w-full md:w-auto;
      @apply max-h-16 mr-8 mt-8 md:mt-0;
    }

    .name {
      @apply w-full md:w-auto;
      @apply text-15-23 md:text-18-27 font-medium;
    }

    .description {
      @apply w-full md:w-auto;
      @apply text-15-23 md:text-18-27;
    }
  }
}
