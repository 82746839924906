.terminalWrapper {
  @apply mb-20 border-b border-gray-terminal-border;

  .photoWrapper {
    @apply mb-10;

    .photo {
      @apply w-full h-[340px];
      @apply flex items-center justify-center;
      @apply rounded-2xl;

      &.isMain {
        @apply bg-yellow;
      }
      &.isSecondary {
        @apply bg-gray-lightestest;
      }
    }

    .photoSmall {
      @apply w-full h-[88px];
      @apply flex items-center justify-center;
      @apply bg-gray-lightestest rounded-2xl;

      &.isMain {
        @apply bg-yellow;
      }
      &.isSecondary {
        @apply bg-gray-lightestest;
      }
    }
  }

  .title {
    @apply md:text-40-55 text-24-35 font-medium;
    @apply mb-6;
  }

  .subtitle {
    @apply md:text-18-27 text-15-23 font-medium;
    @apply mb-4;
  }

  .featureWrapper {
    @apply mb-6;

    .featureList {
      @apply px-0;

      li {
        @apply w-full flex items-start;
        @apply mb-2 p-0;

        svg {
          @apply w-[26px] mr-2 mt-1 stroke-[3px] text-yellow;
          @apply flex-shrink-0;
        }

        &:before {
          @apply content-none;
        }
      }
    }
  }

  .paymentMethodsWrapper {
    @apply mb-20;

    .heading {
      @apply md:text-18-27 text-15-23 font-medium;
      @apply mb-4;
    }
  }
}
