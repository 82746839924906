.containerInner {
  @apply w-full h-full container;

  .textContent {
    @apply w-full flex flex-wrap;

    &.alignCenter {
      @apply text-center justify-center;
    }
    &.alignLeft {
      @apply text-left justify-start;
    }
    &.alignRight {
      @apply text-right justify-end;
    }

    .headline {
      @apply w-full;
      @apply md:text-40-55 text-24-35 font-medium;
      @apply mb-6;
    }

    .teaser {
      @apply lg:w-2/3;
      @apply md:text-24-35 text-15-23;
    }
  }
}
