.sectionWrapper {
  div {
    @apply container relative bg-red-300;
    img {
      @apply container w-full mx-auto rounded-2xl;
    }
  }
}

.wysiwyg {
  h1 {
  }

  h2 {
  }

  h3 {
    @apply text-24-35 font-medium mt-6;
  }

  h4 {
    @apply text-18-27 font-medium mb-8;
  }

  h5 {
  }

  h6 {
  }

  p {
    @apply mb-8 mt-0 last:mb-0;
  }

  a {
    @apply text-[#5669A6] underline;
  }

  ol,
  ul {
    li {
      @apply mb-2;
      padding-inline-start: 1rem;
    }
  }

  :global(.text-blue) {
    @apply text-[#5669A6];
  }

  :global(.text-yellow) {
    @apply text-[#DB9F07];
  }

  :global(.text-green) {
    @apply text-[#3B863B];
  }

  :global(.gray-background) {
    @apply bg-[#E6E8F2] px-[10px] py-[2px] rounded-md;
  }
}
